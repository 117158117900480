export const inventario = [
    {
        
        tipo_orden: "",
        numero_orden: "",
        no_factura: "",
        empresa: "CONSORCIO PROMOTING C.A.",
        region: "CAPITAL",
        fecha_creacion: "",
        cuenta_dotar: "",
        empleado_interno: "",
        cliente_op: "",
        orden_op: "",
        plan: "",
        responsable: "",
        detalle_orden: [
            {
                articulo: "",
                tipo: "",
                monto_unitario: 0,
                cantidad: 5,
                monto_total: 0,
                disponible: 0
            }
        ],
        observaciones: ""
    }
]


